import React, { useState, useEffect } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import homeData from "../data/home-data";

function Slider() {
    const { images } = homeData;

    const [slider, setSlider] = useState(images);
    const [index, setIndex] = useState(0);

    const nextSlide = () => {
        setIndex((oldIndex) => {
            let index = oldIndex + 1;
            if (index > slider.length - 1) {
                index = 0;
            }
            return index;
        });
    };
    const prevSlide = () => {
        setIndex((oldIndex) => {
            let index = oldIndex - 1;
            if (index < 0) {
                index = slider.length - 1;
            }
            return index;
        });
    };

    useEffect(() => {
        const lastIndex = slider.length - 1;
        if (index < 0) {
            setIndex(lastIndex);
        }
        if (index > lastIndex) {
            setIndex(0);
        }
    }, [index, slider]);

    useEffect(() => {
        let slider = setInterval(() => {
            setIndex((oldIndex) => {
                let index = oldIndex + 1;
                if (index > slider.length - 1) {
                    index = 0;
                }
                return index;
            });
        }, 20000);
        return () => {
            clearInterval(slider);
        };
    }, [index]);

    return (
        <div className='slider'>
            <div className='gradient-overlay'></div>
            {slider.map((bg, bgIndex) => {
                let position = "nextSlide";
                if (bgIndex === index) {
                    position = "activeSlide";
                }
                if (
                    bgIndex === index - 1 ||
                    (index === 0 && bgIndex === slider.length - 1)
                ) {
                    position = "lastSlide";
                }

                return (
                    <div
                        className={`background ${position}`}
                        key={bg.id}
                        style={{ backgroundImage: `url(${bg.url})` }}></div>
                );
            })}
            <div className='prev slider-button' onClick={prevSlide}>
                <IoIosArrowBack />
            </div>
            <div className='next slider-button' onClick={nextSlide}>
                <IoIosArrowForward />
            </div>
        </div>
    );
}

export default Slider;
