import React, { useState, useEffect } from "react";
import { cercetareData } from "../../data/content-data";
import { useGlobalContext } from "../context";

function Cercetare() {
    const { language, changeCurrentTab } = useGlobalContext();

    const { id, text, image } = cercetareData;
    const [currentLanguage, setCurrentLanguage] = useState(text[language]);

    useEffect(() => {
        changeCurrentTab(id);
    }, []);

    useEffect(() => {
        setCurrentLanguage(text[language]);
    });

    return (
        <main>
            <section className='page-right'>
                <div className='left'>
                    <div className='page-image'>{image}</div>
                </div>
                <div className='right'>
                    <h2 className='title'>{currentLanguage.title}</h2>
                    <ul className='list'>
                        {currentLanguage.content.map((item, index) => {
                            return <li key={index}>{item}</li>;
                        })}
                    </ul>
                    <div className='partners'>
                        {currentLanguage.partners.map((partner, index) => {
                            return <img src={partner} key={index} alt='' />;
                        })}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Cercetare;
