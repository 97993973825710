import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../context";
import { aboutData } from "../../data/content-data";
import { MdEmail } from "react-icons/md";

function Despre() {
    const { language, changeCurrentTab } = useGlobalContext();

    const { id, text, image } = aboutData;

    const [currentLanguage, setCurrentLanguage] = useState(text[language]);

    useEffect(() => {
        changeCurrentTab(id);
    }, [id]);

    useEffect(() => {
        setCurrentLanguage(text[language]);
    }, [language]);

    return (
        <main>
            <section className='about'>
                <article className='content'>
                    <h2 className='title'>{currentLanguage.title}</h2>
                    <ul>
                        {currentLanguage.content.map((item, index) => {
                            return (
                                <li key={index} className='text'>
                                    {item}
                                </li>
                            );
                        })}
                    </ul>
                </article>
                <article className='logo'>
                    <div className='logo-image'>{image}</div>
                </article>
                <article className='location'>
                    <img
                        className='map'
                        src={currentLanguage.location}
                        alt=''
                    />
                </article>
                <footer className='footer'>
                    <a
                        href={currentLanguage.footer.certification.link}
                        target='_blank'>
                        {currentLanguage.footer.certification.text}
                    </a>
                    <a
                        href={currentLanguage.footer.confidentiality.link}
                        target='_blank'>
                        {currentLanguage.footer.confidentiality.text}
                    </a>
                    <a
                        href={currentLanguage.footer.qualityPolicy.link}
                        target='_blank'>
                        {currentLanguage.footer.qualityPolicy.text}
                    </a>
                    <p>
                        <MdEmail size='1.5rem' />
                        {currentLanguage.footer.email}
                    </p>
                    <p>{currentLanguage.footer.copyright}</p>
                </footer>
            </section>
        </main>
    );
}

export default Despre;
