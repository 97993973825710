import React, { useState, useEffect } from "react";
import { useGlobalContext } from "./context";
import { formContent } from "../data/content-data";

function Form() {
    const downloadUrl =
        "https://medicalimaging.ro/Cloud/Services/WebApi/Prezentare/SendEmailDownloadViewer";
    const messageUrl =
        "https://medicalimaging.ro/Cloud/Services/WebApi/Prezentare/SendSupportEmail";

    const {
        language,
        isFormOpen,
        isMessageForm,
        closeForm,
        openPopup,
        loading,
        gotError,
    } = useGlobalContext();

    const content = formContent;

    const [currentLanguage, setCurrentLanguage] = useState(content[language]);

    const [client, setClient] = useState({
        Name: "",
        Surname: "",
        Email: "",
        Phone: "",
        Message: "",
    });

    const [isNameValid, setIsNameValid] = useState(false);
    const [isSurnameValid, setIsSurnameValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isMessageValid, setIsMessageValid] = useState(!isMessageForm);
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setClient({ ...client, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            if (isMessageForm) {
                openPopup(true);
                sendRequest(client, messageUrl);
            } else {
                openPopup(false);
                sendRequest(client, downloadUrl);
            }
        }
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const onFormClosed = () => {
        setIsNameValid(false);
        setIsSurnameValid(false);
        setIsEmailValid(false);
        setIsPhoneValid(false);
        setIsMessageValid(false);
        setIsChecked(false);
        setClient({ Name: "", Surname: "", Email: "", Phone: "", Message: "" });
        closeForm();
    };

    function validate() {
        setIsNameValid(client.Name !== "");
        setIsSurnameValid(client.Surname !== "");
        setIsEmailValid(validateEmail(client.Email));
        setIsPhoneValid(validatePhoneNumber(client.Phone));
        if (isMessageForm) {
            setIsMessageValid(client.Message !== "");
        }

        return (
            isNameValid &&
            isSurnameValid &&
            isEmailValid &&
            isPhoneValid &&
            (!isMessageForm || isMessageValid)
        );
    }

    const sendRequest = async (client, url) => {
        loading(true);
        gotError(false);

        try {
            await fetch(url, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(client),
            });
            loading(false);
            onFormClosed();
        } catch (error) {
            gotError(true);
            loading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        setCurrentLanguage(content[language]);
    }, [language, content]);

    return (
        <>
            {isFormOpen && (
                <div className='form-component'>
                    <div className='overlay' onClick={onFormClosed}></div>

                    <div className='form-container'>
                        <form className='form'>
                            <div className='form-field' id='move-in'>
                                <input
                                    type='text'
                                    name='Name'
                                    autoComplete='off'
                                    required
                                    value={client.Name}
                                    onChange={handleChange}
                                />
                                <label
                                    htmlFor='Name'
                                    className='label-placeholder'>
                                    <span
                                        className='content'
                                        id='nume'
                                        style={
                                            isNameValid ? {} : { color: "red" }
                                        }>
                                        {isNameValid
                                            ? `${currentLanguage.fields.Name}`
                                            : `${currentLanguage.fields.Name}*`}
                                    </span>
                                </label>
                            </div>

                            <div className='form-field' id='move-in'>
                                <input
                                    type='text'
                                    name='Surname'
                                    autoComplete='off'
                                    required
                                    value={client.Surname}
                                    onChange={handleChange}
                                />
                                <label
                                    htmlFor='Surname'
                                    className='label-placeholder'>
                                    <span
                                        className='content'
                                        id='prenume'
                                        style={
                                            isSurnameValid
                                                ? {}
                                                : { color: "red" }
                                        }>
                                        {isSurnameValid
                                            ? `${currentLanguage.fields.Surname}`
                                            : `${currentLanguage.fields.Surname}*`}
                                    </span>
                                </label>
                            </div>

                            <div className='form-field' id='move-in'>
                                <input
                                    type='text'
                                    name='Email'
                                    id='mail'
                                    autoComplete='off'
                                    required
                                    value={client.Email}
                                    onChange={handleChange}
                                />
                                <label
                                    htmlFor='Email'
                                    className='label-placeholder'>
                                    <span
                                        className='content'
                                        id='e-mail'
                                        style={
                                            isEmailValid ? {} : { color: "red" }
                                        }>
                                        {isEmailValid
                                            ? `${currentLanguage.fields.Email}`
                                            : `${currentLanguage.fields.Email}*`}
                                    </span>
                                </label>
                            </div>

                            <div className='form-field' id='move-in'>
                                <input
                                    type='text'
                                    name='Phone'
                                    autoComplete='off'
                                    required
                                    value={client.Phone}
                                    onChange={handleChange}
                                />
                                <label
                                    htmlFor='Phone'
                                    className='label-placeholder'
                                    id='telefon'>
                                    <span
                                        className='content'
                                        id='telefon-label'
                                        style={
                                            isPhoneValid ? {} : { color: "red" }
                                        }>
                                        {isPhoneValid
                                            ? `${currentLanguage.fields.Phone}`
                                            : `${currentLanguage.fields.Phone}*`}
                                    </span>
                                </label>
                            </div>

                            <div className='form-field-mesaj' id='move-in'>
                                <textarea
                                    name='Message'
                                    id='mesaj'
                                    cols='50'
                                    rows='10'
                                    autoComplete='off'
                                    required
                                    value={client.Message}
                                    onChange={handleChange}></textarea>
                                <label
                                    htmlFor='Message'
                                    className='label-placeholder'>
                                    <span
                                        className='content'
                                        id='mesaj-label'
                                        style={
                                            isMessageForm
                                                ? isMessageValid
                                                    ? {}
                                                    : { color: "red" }
                                                : {}
                                        }>
                                        {isMessageForm
                                            ? isMessageValid
                                                ? `${currentLanguage.fields.Message}`
                                                : `${currentLanguage.fields.Message}*`
                                            : `${currentLanguage.fields.Message}`}
                                    </span>
                                </label>
                            </div>
                            {!isMessageForm && (
                                <div
                                    className='form-field-checkbox'
                                    id='move-in'>
                                    <input
                                        type='checkbox'
                                        name='Tremeni'
                                        id='termeni'
                                        onChange={handleCheckboxChange}
                                    />
                                    <label
                                        htmlFor='Termeni'
                                        className='label-placeholder'>
                                        {currentLanguage.checkBoxText[0]}
                                        <a
                                            href={
                                                currentLanguage.checkBoxText[2]
                                            }
                                            target='_blank'
                                            rel='noreferrer'>{` ${currentLanguage.checkBoxText[1]} `}</a>
                                        {currentLanguage.checkBoxText[3]}
                                    </label>
                                </div>
                            )}

                            <button
                                className='submit-button'
                                id='move-in-button'
                                onClick={handleSubmit}
                                disabled={!(isMessageForm || isChecked)}>
                                {isMessageForm
                                    ? `${currentLanguage.buttonText[1]}`
                                    : `${currentLanguage.buttonText[0]}`}
                            </button>
                        </form>
                        <div className='image'>
                            {isMessageForm
                                ? currentLanguage.messageImage
                                : currentLanguage.downloadImage}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePhoneNumber(phone) {
    const phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    return phoneno.test(String(phone).toLowerCase());
}

export default Form;
