import React, { useState, useContext } from "react";
import { supportedLanguages } from "../data/content-data";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const languages = supportedLanguages;

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [language, setlanguage] = useState(0);
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [isMessageForm, setIsMessageForm] = useState(true);
    const [currentTab, setCurrentTab] = useState(-1);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const loading = (loadState) => {
        setIsLoading(loadState);
    };

    const gotError = (errorState) => {
        setIsError(errorState);
    };

    const openForm = (isMessage) => {
        setIsMessageForm(isMessage);
        setIsFormOpen(true);
    };

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const closeForm = () => {
        setIsFormOpen(false);
    };

    const toggleTheme = () => {
        setIsDarkTheme(!isDarkTheme);
    };

    const changeLanguage = () => {
        var index = getNextIndex(languages, language);
        setlanguage(index);
    };

    const changeCurrentTab = (index) => {
        setCurrentTab(index);
    };

    const getNextIndex = (items, currentIndex) => {
        let index = currentIndex + 1;
        if (index === items.length) {
            return 0;
        }

        return index;
    };

    return (
        <AppContext.Provider
            value={{
                isSidebarOpen,
                isFormOpen,
                language,
                isDarkTheme,
                isMessageForm,
                isPopupOpen,
                isLoading,
                isError,
                currentTab,
                toggleSidebar,
                openForm,
                closeForm,
                changeLanguage,
                toggleTheme,
                changeCurrentTab,
                openPopup,
                closePopup,
                loading,
                gotError,
            }}>
            {children}
        </AppContext.Provider>
    );
};

export const useGlobalContext = () => {
    return useContext(AppContext);
};

export { AppContext, AppProvider };
