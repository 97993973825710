import React, { useEffect, useState } from "react";
import navLinks from "../data/nav-links";
import { useGlobalContext } from "./context";
import { Link } from "react-router-dom";
import { AiOutlineMenuUnfold, AiOutlineCloseCircle } from "react-icons/ai";
// import { FiMoon, FiSun } from "react-icons/fi";

function SideBar() {
    const {
        language,
        isSidebarOpen,
        currentTab,
        toggleSidebar,
        changeLanguage,
        changeCurrentTab,
    } = useGlobalContext();

    const [currentLanguage, setCurrentLanguage] = useState(navLinks[language]);
    const links = currentLanguage.links;

    const handleLinkClick = (index) => {
        changeCurrentTab(index);
        if (window.innerWidth < 768) {
            toggleSidebar();
        }
    };

    useEffect(() => {
        setCurrentLanguage(navLinks[language]);
    }, [language]);

    return (
        <nav className={isSidebarOpen ? "sidebar-open" : "sidebar-closed"}>
            <div className='toggle-sidebar' onClick={() => toggleSidebar()}>
                {isSidebarOpen ? (
                    <AiOutlineCloseCircle />
                ) : (
                    <AiOutlineMenuUnfold />
                )}
            </div>
            <div className='sidebar-container'>
                <div></div>
                <ul className='sidebar-links'>
                    {links.map((item, index) => {
                        return (
                            <li key={index} className='content-link'>
                                <div
                                    className={
                                        currentTab === index
                                            ? "link-background active-link"
                                            : "link-background"
                                    }></div>
                                <div className='icon'>
                                    <Link
                                        to={item.link}
                                        onClick={() => handleLinkClick(index)}>
                                        {item.icon}
                                    </Link>
                                </div>
                                {/* {isSidebarOpen && <Link to={item.link}>{item.page}</Link>} */}
                            </li>
                        );
                    })}
                </ul>
                <div className='appearence'>
                    <div className='language'>
                        <h5 onClick={() => changeLanguage()}>
                            {currentLanguage.language}
                        </h5>
                    </div>
                    {/* <div className="theme" onClick={() => toggleTheme()}>
                        {isDarkTheme ? <FiSun/> : <FiMoon/>}
                    </div> */}
                </div>
            </div>
        </nav>
    );
}

export default SideBar;
