import React, { useState, useEffect } from "react";
import { cloudData } from "../../../data/content-data";
import { useGlobalContext } from "../../context";

function Cloud() {
    const { language, changeCurrentTab } = useGlobalContext();

    const { id, text, image } = cloudData;
    const [currentLanguage, setCurrentLanguage] = useState(text[language]);

    useEffect(() => {
        changeCurrentTab(id);
    }, []);

    useEffect(() => {
        setCurrentLanguage(text[language]);
    }, [language]);

    return (
        <main>
            <section className='page-right'>
                <div className='left'>
                    <div className='page-image'>{image}</div>
                </div>
                <div className='right'>
                    <h2 className='title'>{currentLanguage.title}</h2>
                    <ul className='list'>
                        <li>{currentLanguage.content}</li>
                    </ul>
                    <div></div>
                </div>
            </section>
        </main>
    );
}

export default Cloud;
