import slide1 from "../assets/gif/slide-6.gif";
import slide2 from "../assets/gif/slide-5.gif";
import slide3 from "../assets/gif/slide-7.gif";

const homeData = {
    text: [
        {
            language: "Ro",
            logo: "SOFTWARE MEDICAL",
            title: "Viziunea noastră",
            content:
                "Cel mai inalt criteriu al politicii noastre de calitate este satisfacția clienților noștri! Sistemul de management al calității joacă un rol central în activitatea noastră zilnică și este aplicat în mod constant. PixelData, oferă soluții intuitive, adaptabile și inteligente orientate exact către nevoile dumneavoastră!",
        },
        {
            language: "En",
            logo: "MEDICAL SOFTWARE",
            title: "Our Vision",
            content:
                "The highest criterion of our quality policy is the satisfaction of our customers! The quality management system plays a central role in our daily activity and is constantly applied. PixelData, offers intuitive, adaptable and intelligent solutions oriented exactly to your needs!",
        },
    ],
    images: [
        {
            id: 1,
            url: slide2,
        },
        {
            id: 2,
            url: slide1,
        },
        {
            id: 3,
            url: slide3,
        },
    ],
};

export default homeData;
