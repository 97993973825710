import { VscHome } from 'react-icons/vsc';
import { BiSupport } from 'react-icons/bi';
import { MdShop } from 'react-icons/md';
import {
    AiOutlineFileSearch,
    AiOutlineCloudServer,
    AiOutlineInfoCircle,
} from 'react-icons/ai';

const navLinks = [
    {
        language: 'En',
        links: [
            {
                page: 'Acasa',
                icon: <VscHome />,
                link: '/',
            },
            {
                page: 'Produse',
                icon: <MdShop />,
                link: '/Produse',
            },
            {
                page: 'Clouding',
                icon: <AiOutlineCloudServer />,
                link: '/Clouding',
            },
            {
                page: 'Suport',
                icon: <BiSupport />,
                link: '/Suport',
            },
            {
                page: 'Cercetare',
                icon: <AiOutlineFileSearch />,
                link: '/Cercetare',
            },
            {
                page: 'Despre',
                icon: <AiOutlineInfoCircle />,
                link: '/Despre',
            },
        ],
    },
    {
        language: 'Ro',
        links: [
            {
                page: 'Home',
                icon: <VscHome />,
                link: '/',
            },
            {
                page: 'Products',
                icon: <MdShop />,
                link: '/Produse',
            },
            {
                page: 'Clouding',
                icon: <AiOutlineCloudServer />,
                link: '/Clouding',
            },
            {
                page: 'Support',
                icon: <BiSupport />,
                link: '/Suport',
            },
            {
                page: 'Research',
                icon: <AiOutlineFileSearch />,
                link: '/Cercetare',
            },
            {
                page: 'About',
                icon: <AiOutlineInfoCircle />,
                link: '/Despre',
            },
        ],
    },
];

export default navLinks;
