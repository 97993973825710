import React, { useState, useEffect } from "react";
import { productsData } from "../../../data/content-data";
import { useGlobalContext } from "../../context";
import { MdEmail } from "react-icons/md";

function Products() {
    const { language, openForm, changeCurrentTab } = useGlobalContext();

    const { text } = productsData;

    const [currentLanguage, setCurrentLanguage] = useState(text[language]);

    useEffect(() => {
        changeCurrentTab(text[language].id);
    }, []);

    useEffect(() => {
        setCurrentLanguage(text[language]);
    }, [language]);

    return (
        <main>
            <section>
                {currentLanguage.content.map((item, index) => {
                    if (item.contentType === "specifications-card") {
                        return (
                            <article key={index} className='secifications-card'>
                                <div className='card'>
                                    <h2 className='title'>{item.title}</h2>
                                    <div className='image'>{item.svg}</div>
                                    {item.image === "" ? (
                                        ""
                                    ) : (
                                        <img src={item.image} alt='' />
                                    )}
                                    <ul className='text'>
                                        {item.specifications.map((spec, i) => {
                                            return <li key={i}>{spec}</li>;
                                        })}
                                    </ul>
                                    <button
                                        className='border-button'
                                        onClick={() =>
                                            openForm(
                                                item.formType === "message"
                                            )
                                        }>
                                        {item.buttonText}
                                    </button>
                                </div>
                            </article>
                        );
                    } else if (item.contentType === "image-cards") {
                        return (
                            <article
                                key={index}
                                className='image-cards-container'>
                                {item.cards.map((card, i) => {
                                    return (
                                        <div key={i} className='card'>
                                            <h2 className='title'>
                                                {card.title}
                                            </h2>
                                            <img src={card.image} alt='' />
                                            <ul className='text'>
                                                {card.specifications.map(
                                                    (spec, j) => {
                                                        return (
                                                            <li key={j}>
                                                                {spec}
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                            <button
                                                className='border-button'
                                                onClick={() =>
                                                    openForm(
                                                        card.formType ===
                                                            "message"
                                                    )
                                                }>
                                                {card.buttonText}
                                            </button>
                                        </div>
                                    );
                                })}
                            </article>
                        );
                    }
                })}
                <footer className='footer'>
                    <a
                        href={currentLanguage.footer.certification.link}
                        target='_blank'>
                        {currentLanguage.footer.certification.text}
                    </a>
                    <a
                        href={currentLanguage.footer.confidentiality.link}
                        target='_blank'>
                        {currentLanguage.footer.confidentiality.text}
                    </a>
                    <a
                        href={currentLanguage.footer.qualityPolicy.link}
                        target='_blank'>
                        {currentLanguage.footer.qualityPolicy.text}
                    </a>
                    <p>
                        <MdEmail size='1.5rem' />
                        {currentLanguage.footer.email}
                    </p>
                    <p>{currentLanguage.footer.copyright}</p>
                </footer>
            </section>
        </main>
    );
}

export default Products;
