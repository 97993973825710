import SideBar from './components/SideBar';
import Form from './components/Form';
import Popup from './components/Popup';
import Home from './components/Home';
import Suport from './components/content-items/Suport';
import Cercetare from './components/content-items/Cercetare';
import Despre from './components/content-items/Despre';
import Slider from './components/Slider';
import Products from './components/content-items/proiecte/Products';
import Cloud from './components/content-items/proiecte/Cloud';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
    return (
        <Router>
            <SideBar />
            <Form />
            <Popup />
            <Switch>
                <Route exact path='/'>
                    <Home />
                    <Slider />
                </Route>
                <Route path='/Produse'>
                    <Products />
                </Route>
                <Route path='/Clouding'>
                    <Cloud />
                </Route>
                <Route path='/Suport'>
                    <Suport />
                </Route>
                <Route path='/Cercetare'>
                    <Cercetare />
                </Route>
                <Route path='/Despre'>
                    <Despre />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
