import React, { useState, useEffect } from 'react';
import { useGlobalContext } from './context';
import { popupContent } from '../data/content-data';

function Popup() {
    const {
        language,
        isPopupOpen,
        isLoading,
        isError,
        isDarkTheme,
        isMessageForm,
        closePopup
    } = useGlobalContext();

    const content = popupContent;
    const [currentLanguage, setCurrentLanguage] = useState(content[language]);


    const handleClosing = () => {
        if (!isLoading){
            closePopup();
        }
    };

    useEffect(() => {
        setCurrentLanguage(content[language]);
    }, [language]);

    return (
        <>
            {
                isPopupOpen &&
                <div className={isDarkTheme ? 
                        "" : 
                        "popup-container-light"}>
                    <div className="overlay" onClick={handleClosing}></div>
                    <div className="popup" onClick={handleClosing}>
                        {isLoading ? 
                            <>
                                <img src={currentLanguage.loading} alt=""/>
                            </> : 
                            <>
                                <img src={isError ? 
                                    currentLanguage.errorImage : 
                                    currentLanguage.succesImage}/>
                                <p className="popup-message">
                                    {isError ? 
                                        currentLanguage.textEroare : 
                                        (isMessageForm ? currentLanguage.textMessage :
                                                        currentLanguage.textDownload)}
                                </p>
                            </>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Popup

