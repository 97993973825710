import vlImage from "../assets/gif/viewer-lite.gif";
import vWebImage from "../assets/gif/viewer-web.gif";
import v2dImage from "../assets/gif/slide-7.gif";
import vpImage from "../assets/gif/slide-5.gif";
import loading from "../assets/gif/loading.gif";
import location from "../assets/location.PNG";
import printServer from "../assets/printServer.PNG";
import sp from "../assets/partners/sp.png";
import ubb from "../assets/partners/ubb.svg";
import utc from "../assets/partners/utc.png";
import robot from "../assets/robot.png";
import { ReactComponent as CloudSvg } from "../assets/svg/Cloud.svg";
import { ReactComponent as MessageSvg } from "../assets/svg/Message.svg";
import { ReactComponent as DownloadSvg } from "../assets/svg/Download.svg";
import { ReactComponent as ResearchSvg } from "../assets/svg/Research.svg";
import { ReactComponent as SupportSvg } from "../assets/svg/Support.svg";
import { ReactComponent as AboutSvg } from "../assets/svg/About.svg";
import { ReactComponent as CertificationsSvg } from "../assets/svg/Certifications.svg";
import { ReactComponent as PacsSvg } from "../assets/svg/PACS.svg";
import sadFace from "../assets/Sad.png";
import happyFace from "../assets/Smile.png";
import termsAndConditions from "../assets/pdf/termsAndConditions.pdf";
import qualityPolicy from "../assets/pdf/politicaCalitate.pdf";
import certification from "../assets/pdf/certificate.pdf";

const productsData = {
    text: [
        {
            id: 1,
            language: "Ro",
            content: [
                {
                    title: "DICOM Viewers",
                    contentType: "image-cards",
                    cards: [
                        {
                            title: "Viewer 2D Lite",
                            specifications: [
                                "Acest tip de viewer DICOM are funcționalitatea minimă pentru a vizualiza imagini medicale.",
                                "Rulează pe orice tip de Windows.",
                                "Are o interfață intuitivă și ușor de utilizat.",
                                "Ferestre preformatate (plaman, os, abdomen, mediastin, creier, etc.)",
                                "Masuratori de baza",
                            ],
                            image: vlImage,
                            formType: "download",
                            buttonText: "Descarcă",
                        },
                        {
                            title: "Viewer 3D Pro",
                            specifications: [
                                "Aplicație software desktop pentru postprocesare și reconstrucție 3D, MPR, MIP (MinIP, MIP, Mean si Sum), Volume Rendering.",
                                "Integrare cu aplicații de Inteligență Artificială.",
                                "Reconstrucție 3D prin tehnica: Isosurface, Transfer Function, Maximum Intensity Projection.",
                                "Spine labeling.",
                                "Unealtă de tip baghetă magică.",
                                "Filtre de procesare imagine: sharpness, blur, emboss.",
                                "Vizualizare și procesare imagini DICOM comprimate Jpeg2000.",
                                "Funcție de Stitching pentru imagini de tip RX pentru două sau mai multe imagini și obținerea unei noi imagini DICOM.",
                            ],
                            image: vpImage,
                            formType: "message",
                            buttonText: "Contactați-ne",
                        },
                        {
                            title: "Viewer 2D",
                            specifications: [
                                "Acest tip de viewer DICOM este ideal pentru calculatoarele mai puțin performante utilizând la maximum resursele acestora.",
                                "Rulează pe orice tip de Windows.",
                                "Are la bază standardul DICOM și se poate conecta la orice PACS compatibil cu acest standard.",
                                "Este un viewer 2D care are toate uneltele necesare pentru diagnostic imagistic.",
                                "Printre funcțiile avansate are inclusă și fuziunea PET/CT.",
                                "Include un sistem de incapsulare DICOM a documentelor.",
                                "Se poate conecta la mai multe servere PACS simultan.",
                                "Sincronizare multiplanară – posibilitatea vizualizării unei leziuni sincron pe cele 3 plane in timp real.",
                                "Moduri de afișare: mod comparativ, mod cine, sincronizare automata contrast/fara contrast.",
                                "Linii de referintă între plane.",
                                "Funcții de căutare după următoarele câmpuri principale: ID Pacient, Nume Pacient, Tipuri de echipamente, Număr investigație, ID studiu, Descriere Studiu, Descriere Serie.",
                                "MPR (Reformatare Multiplanară)",
                                "Ferestre preformatate (plaman, os, abdomen, mediastin, creier, etc.)",
                            ],
                            image: v2dImage,
                            formType: "message",
                            buttonText: "Contactați-ne",
                        },
                        {
                            title: "Viewer Web",
                            specifications: [
                                "Aplicație web pentru vizualizare imagini medicale conectată direct la PACS; Aplicația web este de tip “thin Client” și zero footprint.",
                                "Aplicația de tip web poate să funcționeze pe orice tip de dispozitiv mobil (tabletă, telefon) și pe orice sistem de operare (iOS, Andoid, Windows, etc).",
                                "Aplicația de tip web permite vizualizarea imaginilor pentru orice tip de modalitate DICOM (DX, XA, CT,  RM, CR, etc ).",
                                "Posibilitatea de a vizualiza imaginile medicale pe orice tip de tabletă sau telefon mobil cu sistem de operare (iOS, Android si Windows).",
                                "Nu este necesară instalarea de aplicații suplimentare pe calculator pentru a rula aplicația web dedicată vizualizării de imagini medicale (DICOM).",
                            ],
                            image: vWebImage,
                            formType: "message",
                            buttonText: "Contactați-ne",
                        },
                    ],
                },
                {
                    title: "Server PACS",
                    contentType: "specifications-card",
                    specifications: [
                        "Arhiva de mare capacitate (PACS). Fără limitare de licență software referitoare la numărul pacienților, studiilor, imaginilor sau la capacitatea de stocare.",
                        "Arhitectură pe trei nivele (nivelul client, nivelul server, nivelul baze de date).",
                        "Posibilitatea ca serverul de PACS să lucreze cu 3 locații de stocare:  Locația curentă, Locația pentru arhivă, Locața de backup.",
                        "Compresia imaginilor in arhivă se face cu rata de compresie între 30% și 50%. Compresia este 100% reversibilă. Acțiunea este complet transparentă pentru utilizator. Compresia se face fără a folosi transformate.",
                        "Posibilitate de redirectare de studii pe mai multe conexiuni simultan bazat pe numărul de serii DICOM. Posibilitatea configurării numărului de conexiuni care se pot deschide simultan.",
                        "Posibilitatea transferului securizat de imagini utilizând protocolul DICOM Message Service Element (DIMSE) prin protocolul de securitate SSL (Secure Sockets Layer).",
                        "Colectarea automată a dozelor de iradiere din examinarile de tip: CT, RX, MAMO, CR, PET/CT, XA și NM.",
                        "Serviciu online 24/7 pentru notificarea medicilor radiologi pe dipozitive mobile cu privire la starea pacienților și examinărilor din departamentul de radiologie și imagistică medicală.",
                    ],
                    svg: <PacsSvg />,
                    image: "",
                    formType: "message",
                    buttonText: "Contactați-ne",
                },
                {
                    title: "Platforma web pentru teleradiologie",
                    contentType: "specifications-card",
                    specifications: [
                        "Este o aplicație software de tip Cloud.",
                        "Aplicațiile web din platformă sunt de tip “thin Client”, zero footprint și rulează in orice tip de browser actualizat.",
                        "Prin această platfomă se pot interconecta Centrele de Imagistică și Centrele de Diagnostic (Diagnostic hub) pentru vizualizarea imaginilor și redactarea rezultatelor.",
                        "Interfață intuitivă și ușor de utilizat.",
                        "Este o platfomă securizată, accesarea ei se face prin două nivele de securitate.",
                        "Prin această platformă un medic poate sa fie conectatat la mai multe centre de imagistică simultan.",
                        "Există posibilitatea de a incarca imagini in platfomă atat in mod direct, de la echipamentele de imagistică cat și de pe unități optice sau unități de stocare (HDD).",
                        "Medicul are posibilitatea de a avea acces imediat la istoricul pacienților atât din punct de vedere imagistic cat și al rezultatelor scrise precum și a altor documente atașate.",
                    ],
                    svg: <CloudSvg />,
                    image: "",
                    formType: "message",
                    buttonText: "Contactați-ne",
                },
                {
                    title: "Dicom Media Burner",
                    contentType: "specifications-card",
                    specifications: [
                        "Inscripționare automată pe CD/DVD a studiilor realizate în centrele de imagistică medicală",
                        "Dicom Media Burner este o aplicație destinată automatizării procesului de inscripționare pe medii optice de stocare a studiilor efectuate în centrele de imagistică medicală. Aplicația poate primi solicitări de inscripționare de la orice entități DICOM (programe de vizualizare a imaginilor DICOM, servere PACS, console CT/RMN) capabile să utilizeze comanda C-STORE și le trimite către roboții de inscripționare pe baza regulilor predefinite.",
                    ],
                    svg: "",
                    image: robot,
                    formType: "message",
                    buttonText: "Contactați-ne",
                },
                {
                    title: "DICOM Print Server",
                    contentType: "specifications-card",
                    specifications: [
                        "Acesată aplicație rulează ca și un software de tip server.",
                        "Aplicația software realizează conversia mesajelor DICOM Print in mesaje de tip Postscript pentru imprimante de tip office sau profesionale care au capacitatea să lucreze cu acest limbaj. Această transformare se face pentru ca utilizatorii se aibă posibilitatea de a tipării imagini DICOM pe suport de hârtie.",
                    ],
                    svg: "",
                    image: printServer,
                    formType: "message",
                    buttonText: "Contactați-ne",
                },
                {
                    title: "Standarde și certificări",
                    contentType: "specifications-card",
                    specifications: [
                        "Soluția suportă standardele DICOM 3.0 si HL7 versiunea minima 2.3",
                        "Declarație de conformitate de la producătorul aplicațiilor software de tip PACS",
                        "Certificări: ISO 9001:2015, ISO27001:2013 și ISO 13485:2016 de la producătorul sistemului PACS",
                    ],
                    svg: <CertificationsSvg />,
                    image: "",
                    formType: "message",
                    buttonText: "Contactați-ne",
                },
            ],
            footer: {
                email: " infopxd@pixeldata.ro",
                copyright:
                    "Copyright © 2021 PixelData Toate drepturile rezervate",
                confidentiality: {
                    text: "Politica de confidențialitate",
                    link: termsAndConditions,
                },
                qualityPolicy: {
                    text: "Declarația de politică referitoare la calitate",
                    link: qualityPolicy,
                },
                certification: {
                    text: "Certificări",
                    link: certification,
                },
            },
        },
        {
            id: 1,
            language: "En",
            content: [
                {
                    title: "DICOM Viewers",
                    contentType: "image-cards",
                    cards: [
                        {
                            title: "Viewer 2D Lite",
                            specifications: [
                                "This type of DICOM viewer has minimal functionality for viewing medical images.",
                                "Runs on any type of Windows.",
                                "It has an intuitive and easy to use interface.",
                                "Preformatted windows (lung, bone, abdomen, mediastinum, brain, etc.)",
                                "Basic measurements",
                            ],
                            image: vlImage,
                            formType: "download",
                            buttonText: "Download",
                        },
                        {
                            title: "Viewer 3D Pro",
                            specifications: [
                                "Desktop software application for post-processing and 3D reconstruction, MPR, MIP (MinIP, MIP, Mean and Sum), Volume Rendering.",
                                "Integration with Artificial Intelligence applications.",
                                "3D reconstruction by technique: Isosurface, Transfer Function, Maximum Intensity Projection.",
                                "Spine labeling.",
                                "Magic wand tool.",
                                "Image processing filters: sharpness, blur, emboss.",
                                "Viewing and processing DICOM Jpeg2000 tablets images.",
                                "Stitching function for RX images for 2 or more images and obtaining a new DICOM image.",
                            ],
                            image: vpImage,
                            formType: "message",
                            buttonText: "Contactat us",
                        },
                        {
                            title: "Viewer 2D",
                            specifications: [
                                "This type of DICOM viewer is ideal for less powerful computers using their resources to the fullest.",
                                "Runs on any type of Windows.",
                                "It is based on the DICOM standard and can be connected to any PACS compatible with this standard.",
                                "It is a 2D Viewer that has all the necessary tools for imaging diagnostics.",
                                "Advanced features include PET / CT fusion.",
                                "Includes a DICOM document encapsulation system.",
                                "It can connect to several PACS servers simultaneously.",
                                "Multiplanar synchronization - the possibility of viewing a synchronous lesion on the 3 planes in real time.",
                                "Display modes: comparative mode, cine mode, automatic contrast / non-contrast synchronization.",
                                "Reference lines between planes.",
                                "Search functions by the following main fields: Patient ID, Patient Name, Equipment Types, Investigation Number, Study ID, Study Description, Series Description.",
                                "MPR (Multiplanar Reformatting)",
                                "Preformatted windows (lung, bone, abdomen, mediastinum, brain, etc.)",
                            ],
                            image: v2dImage,
                            formType: "message",
                            buttonText: "Contact us",
                        },
                        {
                            title: "Web Viewer",
                            specifications: [
                                "Web application for viewing medical images connected directly to PACS; The web application is thin client and zero footprint.",
                                "The web application can work on any type of mobile device (tablet, phone) and on any operating system (iOS, Andoid, Windows, etc.).",
                                "The web application allows viewing images for any type of DICOM mode (DX, XA, CT, RM, CR, etc.).",
                                "Possibility to view medical images on any type of tablet or mobile phone with operating system (iOS, Android and Windows).",
                                "It is not necessary to install additional applications on your computer to run the medical imaging web application (DICOM).",
                            ],
                            image: vWebImage,
                            formType: "message",
                            buttonText: "Contact us",
                        },
                    ],
                },
                {
                    title: "PACS Server",
                    contentType: "specifications-card",
                    specifications: [
                        "High Capacity Archive (PACS). Without limitation of the software license regarding the number of patients, studies, images or storage capacity.",
                        "Three-level architecture (client level, server level, database level).",
                        "Possibility for the PACS server to work with 3 storage locations: Current location, Archive location, Backup location.",
                        "The compression of the images in the archive is done with the compression rate between 30% and 50%. The compression is 100% reversible. The action is completely transparent for user. Compression is done without using transforms.",
                        "Possibility to redirect studies on several connections simultaneously based on the number of DICOM series. Possibility to configure the number of connections that can be opened simultaneously.",
                        "Possibility of secure image transfer using the DICOM Message Service Element (DIMSE) protocol through the Secure Sockets Layer (SSL) security protocol.",
                        "Automatic collection of irradiation doses from type examinations: CT, RX, MAMO, CR, PET / CT, XA and NM.",
                        "24/7 online service for notifying radiologists on mobile devices about the condition of patients and examinations in the department of radiology and medical imaging.",
                    ],
                    svg: <PacsSvg />,
                    image: "",
                    formType: "message",
                    buttonText: "Contact us",
                },
                {
                    title: "Web platform for teleradiology",
                    contentType: "specifications-card",
                    specifications: [
                        "It is a Cloud software application.",
                        "The web applications in the platform are of “thin Client” type and zero footprint and run in any type of updated browser.",
                        "Through this platform, the Imaging Centers and the Diagnostic Hub can be interconnected for viewing the images and writing the results.",
                        "Intuitive and easy to use interface.",
                        "It is a secure platform, accessed through two levels of security.",
                        "Through this platform a doctor can be connected to several imaging centers simultaneously.",
                        "There is the possibility to upload images to the platform both directly from the imaging equipment and from optical drives or storage units (HDD).",
                        "The doctor has the possibility to have immediate access to the patients' history both from the imaging point of view and of the written results as well as of other attached documents.",
                    ],
                    svg: <CloudSvg />,
                    image: "",
                    formType: "message",
                    buttonText: "Contact us",
                },
                {
                    title: "Dicom Media Burner",
                    contentType: "specifications-card",
                    specifications: [
                        "Automatic recording on CD / DVD of studies performed in medical imaging centers.",
                        "Dicom Media Burner is an application designed to automate the process of recording on optical storage media of studies conducted in medical imaging centers. The application can receive enrollment requests from any DICOM entities (DICOM image viewers, PACS servers, CT / MRI consoles) capable of using the C-STORE command and send them to enrollment robots based on predefined rules.",
                    ],
                    svg: "",
                    image: robot,
                    formType: "message",
                    buttonText: "Contact us",
                },
                {
                    title: "DICOM Print Server",
                    contentType: "specifications-card",
                    specifications: [
                        "This application runs as a server software.",
                        "The software application converts DICOM Print messages into Postscript messages for office or professional printers that have the ability to work with this language. This transformation is done so that users can print DICOM images on paper.",
                    ],
                    svg: "",
                    image: printServer,
                    formType: "message",
                    buttonText: "Contact us",
                },
                {
                    title: "Standards and certifications",
                    contentType: "specifications-card",
                    specifications: [
                        "The solution supports DICOM 3.0 and HL7 minimum version 2.3 standards",
                        "Declaration of conformity from the manufacturer of PACS software applications",
                        "Certifications: ISO 9001: 2015, ISO27001: 2013 and ISO 13485: 2016 from the PACS system manufacturer",
                    ],
                    svg: <CertificationsSvg />,
                    image: "",
                    formType: "message",
                    buttonText: "Contact us",
                },
            ],
            footer: {
                email: " infopxd@pixeldata.ro",
                copyright: "Copyright © 2021 PixelData All rights reserved",
                confidentiality: {
                    text: "Terms and conditions",
                    link: termsAndConditions,
                },
                qualityPolicy: {
                    text: "Quality policy",
                    link: qualityPolicy,
                },
                certification: {
                    text: "Certifications",
                    link: certification,
                },
            },
        },
    ],
};

const cloudData = {
    id: 2,
    text: [
        {
            language: "Ro",
            title: "Cloud",
            content:
                "Medical Imaging Cloud furnizează servicii de teleradiologie, arhivare de imagini medicale pe termen lung, copie de siguranță la imaginile medicale, second opinion, transfer securizat de imagini între centre de imagistică sau spitale.",
        },
        {
            language: "En",
            title: "Cloud",
            content:
                "Medical Imaging Cloud provides teleradiology services, long-term medical image archiving, backup to medical images, second opinion, secure image transfer between imaging centers or hospitals.",
        },
    ],
    image: <CloudSvg />,
};

const suportData = {
    id: 3,
    text: [
        {
            language: "Ro",
            title: "Suport",
            content: [
                "Asistență garantată, pe care te poți baza!",
                "Ințelegem cât de important este ca aplicațiile dumneavoastră să fie perfect funcționale, motiv pentru care vă punem la dispoziție echipa noastră specializată de asistență, oferindu-vă suportul tehnic 24 de h/zi.",
                "Personalul nostru este pregătit să găsească soluții rapide astfel încât va răspunde cu promptitudine la nevoile și solicitările dumneavoastră!",
            ],
            buttonText: "Contactați-ne",
        },
        {
            language: "En",
            title: "Support",
            content: [
                "Guaranteed assistance you can count on!",
                "We understand how important it is for your applications to be perfectly functional, which is why we provide you with our specialized support team, offering you technical support 24 hours a day.",
                "Our staff is ready to find quick solutions so that they will respond promptly to your needs and requests!",
            ],
            buttonText: "Contact us",
        },
    ],
    image: <SupportSvg />,
};

const cercetareData = {
    id: 4,
    text: [
        {
            language: "Ro",
            title: "Cercetare",
            content: [
                "PixelData, prin departamentul de cercetare și dezvoltare este implicată in activități și proiecte din acest domeniu in perspectiva dezvoltării și implementării de soluții tehnice noi în care se regăsesc concepte și soluții inovative la nivel mondial.",
                "Suntem preocupați de cercetarea și implementarea celor mai noi și performanți algoritmi și soluții software cu perspectiva în viitor.",
                "Activitățile principale de cercetare și dezvoltare sunt in domeniile de Inteligență Artificială, algoritmi performanți în imagistică medicală, compresia, transferul și criptarea datelor.",
                "Standardizarea interfețelor de comunicație în domeniul medical cu aplicare în standardele DICOM și HL7.",
                "Principali noștri parteneri în proiectele de cercetare și dezvoltare sunt:",
            ],
            partners: [ubb, sp, utc],
        },
        {
            language: "En",
            title: "Research",
            content: [
                "PixelData, through the research and development department, is involved in activities and projects from this field in the perspective of developing and implementing new technical solutions in which innovative concepts and solutions are found worldwide.",
                "We are concerned with researching and implementing the latest and most advanced algorithms and software solutions with a perspective for the future.",
                "The main research and development activities are in the fields of Artificial Intelligence, high-performance algorithms in medical imaging, compression, data transfer and encryption.",
                "Standardization of communication interfaces in the medical field with application in DICOM and HL7 standards.",
                "Our main partners in research and development projects are:",
            ],
            partners: [ubb, sp, utc],
        },
    ],
    image: <ResearchSvg />,
};

const aboutData = {
    id: 5,
    text: [
        {
            language: "Ro",
            title: "Despre noi",
            content: [
                "Datorită experienței noastre de peste 18 ani pe piața din România și din dorința constantă de-a evolua am reușit să îmbunătățim de-a lungul anilor activitatea noastră susținând progresele tehnologice, oferind astfel soluții rapide și adaptate la nevoile clienților.",
                "Prin aplicațiile software proiectate și dezvoltate de PixelData se obține o integrare completă într-un mod nou și inovator permițând astfel clientului să proceseze, să vizualizeze, să partajeze și să stocheze în mod eficient imagini medicale, cum ar fi scanări CT, PET-CT, IRM, RX și ultrasunete.",
                "O etapă de dezvoltare esențială în sistemul de sănătate a fost înlocuirea proceselor tradiționale de imagistică și radiologie cu introducerea și utilizarea pe scara largă a sistemelor integrate de arhivare și comunicare a imaginilor (PACS/ RIS).",
                "Ca tehnologie, am contribuit la accelerarea afișării și achiziției de imagini digitale, la eficientizarea și centralizarea arhivării imaginilor, la consolidarea evolutiei medicale și la pregătirea drumului pentru teleradiologie.",
                "Competența și motivația tuturor angajaților noștri este fundamentală pentru succesul companiei noastre!",
            ],
            location: location,
            footer: {
                email: " infopxd@pixeldata.ro",
                copyright:
                    "Copyright © 2021 PixelData Toate drepturile rezervate",
                confidentiality: {
                    text: "Politica de confidențialitate",
                    link: termsAndConditions,
                },
                qualityPolicy: {
                    text: "Declarația de politică referitoare la calitate",
                    link: qualityPolicy,
                },
                certification: {
                    text: "Certificări",
                    link: certification,
                },
            },
        },
        {
            language: "En",
            title: "About us",
            content: [
                "Due to our experience of over 18 years on the Romanian market and the constant desire to evolve, we have managed to improve our activity over the years by supporting technological advances, thus offering fast solutions and adapted to customer needs.",
                "Through the software applications designed and developed by PixelData, a complete integration is obtained in a new and innovative way, thus allowing the client to efficiently process, view, share and store medical images, such as CT scans, PET-CT, MRI, X-ray and ultrasound.",
                "An essential stage of development in the health system was the replacement of traditional imaging and radiology processes with the introduction and widespread use of integrated image archiving and communication systems (PACS / RIS).",
                "As a technology, we have contributed to the acceleration of the display and acquisition of digital images, to the efficiency and centralization of image archiving, to the consolidation of medical evolution and to the preparation of the road for teleradiology.",
                "The competence and motivation of all our employees is fundamental for the success of our company!",
            ],
            location: location,
            footer: {
                email: " infopxd@pixeldata.ro",
                copyright: "Copyright © 2021 PixelData All rights reserved",
                confidentiality: {
                    text: "Terms and conditions",
                    link: termsAndConditions,
                },
                qualityPolicy: {
                    text: "Quality policy",
                    link: qualityPolicy,
                },
                certification: {
                    text: "Certifications",
                    link: certification,
                },
            },
        },
    ],
    image: <AboutSvg />,
};

const popupContent = [
    {
        language: "Ro",
        textDownload:
            "Un link de descărcare a fost trimis la adresa specificată",
        textMessage:
            "Mesajul dumneavoastră a fost trimis cu succes către echipa PixelData",
        textEroare:
            "Mesajul dumneavoastră nu a putut fi trimis, vă rugăm incercați mai tarziu",
        errorImage: sadFace,
        succesImage: happyFace,
        loading: loading,
    },
    {
        language: "En",
        textDownload:
            "A download link was sent to the e-mail address specified",
        textMessage:
            "Your message was successfully sent to the PixelData support team",
        textEroare: "Your message could not be sent, please try again later",
        errorImage: sadFace,
        succesImage: happyFace,
        loading: loading,
    },
];

const formContent = [
    {
        language: "Ro",
        fields: {
            Name: "Nume",
            Surname: "Prenume",
            Email: "E-mail",
            Phone: "Telefon",
            Message: "Mesaj",
        },
        checkBoxText: [
            "Am citit și sunt de acord cu",
            "termenii și condițiile",
            "acestui site.",
        ],
        buttonText: ["Descarcă", "Trimite"],
        messageImage: <MessageSvg />,
        downloadImage: <DownloadSvg />,
    },
    {
        language: "En",
        fields: {
            Name: "Name",
            Surname: "Surname",
            Email: "E-mail",
            Phone: "Phone number",
            Message: "Message",
        },
        checkBoxText: [
            "I have read and agree with the",
            "terms and conditions",
            termsAndConditions,
            "of this site.",
        ],
        buttonText: ["Download", "Send"],
        messageImage: <MessageSvg />,
        downloadImage: <DownloadSvg />,
    },
];

const supportedLanguages = ["Ro", "En"];

export {
    productsData,
    cloudData,
    suportData,
    cercetareData,
    supportedLanguages,
    popupContent,
    formContent,
    aboutData,
};
