import React, { useState, useEffect } from "react";
import homeData from "../data/home-data";
import { useGlobalContext } from "./context";

function Home() {
    const { language, changeCurrentTab } = useGlobalContext();

    const { text } = homeData;

    const [currentLanguage, setCurrentLanguage] = useState(text[language]);

    useEffect(() => {
        changeCurrentTab(0);
    }, []);

    useEffect(() => {
        setCurrentLanguage(text[language]);
    }, [language]);

    return (
        <header>
            <div className='home'>
                <div className='centered-content'>
                    <div className='logo-element'>
                        <div className='logo'>
                            <h1>
                                Pixel<span>Data</span>
                            </h1>
                            <h3>{currentLanguage.logo}</h3>
                        </div>
                        <div className='right-floater'></div>
                    </div>
                    <div className='motto-container'>
                        <div className='left-floater'></div>
                        <div className='motto'>
                            <h2>{currentLanguage.title}</h2>
                            <p>{currentLanguage.content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Home;
